import React, {useState} from 'react'
import {StaticQuery, graphql, Link } from 'gatsby'

export default function EventsGrid({data}) {

  return (
    <StaticQuery
      query={graphql`
        query EventsGridQuery {
          allWpEvent(sort: {order: ASC, fields: acfEvents___date})  {
            nodes {
              title
              slug
              acfEvents {
                date
                endDate
              }
              content
            }
          }
        }
      `}
      render={data => (
        <section className='events-grid'>
        
          {
            data.allWpEvent.nodes.length > 0 ?
              data.allWpEvent.nodes.map((event, index) => {
                const date = event.acfEvents.date.split(/\//)
                const endDate = event?.acfEvents?.endDate?.split(/\//)
                return (
                  <article key={`event_${index}`}>
                    <Link to={event.slug}>
                      <h2>{event.title}</h2>
                      <div className="date">
                        {[ date[2], date[1], date[0] ].join('/')}
                        {endDate ? ` - ${[ endDate[1], endDate[0], endDate[2] ].join('/')}` : '' }
                      </div>
                      <div className='readmore'>Find out more &rarr;</div>
                    </Link>
                  </article>
                )
              })
            : <h2>Sorry there are no upcoming events available to view</h2>
          }
        </section>
      )}
    />
  )
}